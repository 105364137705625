* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body,
html {
  overflow: hidden;
  overflow-y: scroll;
}

:root {
  --white: #ffffff;
  --white2: #eef0fa;
  --white3: #eef0fa;
  --gray: #c2c2c2;
  --light-gray: #c4c4c4;
  --gray2: #7c7c7c;
  --gray3: #919191;
  --btn-white: #e3e3e3;
  --btn-blue: #0045ff;
  --black: #1c1c1c;
  --card-black: #272727;
  --card-white: #e9edfb;
  --color: #202f44;
  --app-bg: #e2e7fe;
  --app-color: var(--color);
  --app-btn-bg: var(--btn-blue);
  --app-btn-color: var(--white);
  --custom-card-bg: var(--card-white);
  --card-shadow: rgba(156, 172, 242, 0.141);
  --title-color: #00265c;
  --title-color2: var(--color);
  --text-blue: #0045ff;
  --eco-card-bg: var(--color);
  --our-team-title: #4d4d4d;
  --feturecard-shadow: rgba(156, 172, 242, 0.68);
  --footer-li-text: #202f44cc;
}

[data-theme="dark"] {
  --app-bg: #1c1c1c;
  --app-color: var(--btn-white);
  --app-btn-bg: var(--btn-white);
  --app-btn-color: var(--black);
  --custom-card-bg: var(--card-black);
  --card-shadow: rgba(0, 0, 0, 0.141);
  --title-color: var(--btn-white);
  --title-color2: var(--btn-white);
  --text-blue: var(--btn-white);
  --eco-card-bg: var(--white);
  --our-team-title: var(--btn-white);
  --feturecard-shadow: rgba(26, 26, 26, 0.68);
  --footer-li-text: var(--btn-white);
}

body {
  background-color: var(--app-bg) !important;
  color: var(--app-color) !important;
}


.wrapper{
  margin: auto;
  overflow: hidden;
}

.read-more {
  margin: 30px 0px;
}

.platform_container {
  width: 45%;
  margin: 50px auto;
  margin-top: 150px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.platform_container h1 {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  color: var(--color);
  text-align: center;
}

.platform_container h1 span {
  font-weight: bold;
}

.platforms {
  display: flex;
  justify-content: center;
  column-gap: 184px;
}


.platformInner {
  background-color: var(--color);
  padding: 15px 25px;
  border-radius: 10px;
 
}

.pimg {
  width: 250px;
  height: 71px;
}

@media only screen and (max-width: 768px) {
  .platform_container {
    width: 90%;
    height: 100px;
    margin: 30px auto;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .platform_container h1 span {
    line-height: 0px;
  }

  .platform_container h1 {
    font-size: 25px;
  }

  .platforms {
    margin-top: 11px;
    column-gap: 30px;
    padding: 20px 2rem 0 2rem;
  }

  .platformInner {
    background-color: var(--color);
    padding: 5px 20px;
    border-radius: 10px;
    /* width: 70%;
    height: 100%; */
    margin: 10px;
  }

  .pimg {
    width: 120px;
    height: 61px;
  }
}
.theme-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.theme-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  box-shadow: 0 0 1px black;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

video {
  pointer-events: none;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
/* navbar   */

.navBarContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 35px 50px;
  max-width: 2000px;
  margin: auto;
}

.mainLogoContainer {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.mainLogoContainer img {
  width: 202.12px;
  height: 40.14px;
}
#our-community-section {
  position: relative;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-evenly;
  list-style-type: none;
  color: "#202f44";
  position: relative;
}

.header li {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header li a:active {
  text-decoration: none;
}

.headerTextContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  padding-top: 1rem;
  margin-bottom: 62px;
}

.headerTextContainer p {
  font-weight: bold;
  font-size: 3.2663rem;
  font-family: "Montserrat", sans-serif;
  /* margin: 5rem 0; */
  text-align: center;
}

.appBtn {
  width: 148.19px;
  height: 40.88px;
  background: var(--app-btn-bg);
  font-weight: 600;
  font-size: 18.3961px;
  display: grid;
  place-content: center;
  color: #fff;
  padding: 0.2rem 0;
  border: none;
  border-radius: 22.7586px;
  cursor: pointer;
}

.appBtn a {
  text-decoration: none;
  color: "#fff" !important;
}

.btnContainer {
  display: grid;
  place-content: center;
}

.toggleContainer {
  display: none;
  cursor: pointer;
}

.headerNav {
  height: 100vh;
  width: 100%;
  padding: 1rem 5rem;
  z-index: 999;
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.headerNav a {
  text-decoration: none;
}

.hidden {
  display: flex;
}

.dev {
  position: relative;
}

.nav-dropDown {
  position: absolute;
  width: 150px;
  height: 99px;
  background: #e2e7fe;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.08), 2px 4px 3px rgba(0, 0, 0, 0.11);
  border-radius: 9px;
  left: 10%;
  top: 40px;
  padding: 0;
  text-align: justify;
}

.nav-dropDown ul {
  padding: 0 0 0 20px;
}

.nav-dropDown li {
  text-decoration: none;
  list-style: none;
  line-height: 37px;
  color: #202f44;
  font-weight: 700;
  font-size: 14.2575px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .navBarContainer {
    grid-template-columns: 1fr 1fr;
  }
  .header,
  .btnContainer {
    display: none;
  }
  .toggleContainer {
    display: block;
    justify-self: flex-end;
    align-self: center;
  }
  .headerTextContainer p {
    font-size: 30px;
    text-align: center;
    margin: 0;
  }

  .headerTextContainer p::after {
    content: "\a";
    white-space: pre;
  }

  /* .navBarContainer {
    grid-template-columns: 50% 30% 20%;
  } */
}

@media only screen and (max-width: 458px) {
  .navBarContainer {
    grid-template-columns: 4fr 1fr;
    padding: 2rem 1rem;
  }
  .header,
  .btnContainer {
    display: none;
  }
  .toggleContainer {
    display: block;
    margin-right: 1rem;
  }
  .headerTextContainer p {
    font-size: 22px;
    text-align: center;
    margin: 0;
    padding: 0 2rem;
    line-height: 30px;
  }

  .mainLogoContainer {
    padding: 0 1rem;
  }
  .mainLogoContainer img {
    height: 28px;
    width: 137.67px;
  }
}
@media only screen and (min-width: 1500px) {
  .navBarContainer {
    padding: 35px 50px;
  }
}

/* banner img */

.bannerImgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* height: 38rem; */
  max-width: 2000px;
  margin: auto;
  gap: 4rem;
}

.bannerImg {
  width: 80%;
  /* margin: 0rem 0 7rem 0; */
}

.bannerImgMobile {
  width: 80%;
  display: none;
}

.poweredByContainer {
  /* display: flex;
  width: 40%;
  padding: 1rem;
  align-items: center;
  justify-content: space-evenly; */
  display: grid;
  width: 42%;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  padding: 1.2rem 0;
  background: var(--app-btn-bg);
  color: var(--app-btn-color);
  border-radius: 8.13426px;
}

.poweredBy-first-child {
  justify-self: center;
  align-self: center;
}

.poweredBySubContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 6rem;
}

.poweredBySubContainer h1 {
  padding-left: 1rem;
  margin: 0;
}

.poweredByContainer h1 {
  font-size: 22.77px;
  margin: 0;
  font-weight: bold;
}

.colorText {
  color: var(--app-btn-bg);
}

.custom-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 4rem;
  row-gap: 4rem;
  padding: 4rem 4rem 0rem 4rem;
  margin-top: 11rem;
}

.custom-card {
  border-radius: 12px;
  width: 366px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.7rem;
}
.custom-card:hover {
  background: var(--custom-card-bg);
  box-shadow: 10px 4px 10px var(--card-shadow);
}
.custom-card-img {
  width: 16.25rem;
  height: 9.125rem;
  display: grid;
  place-content: center;
}
.shadowBtn {
  margin-left: 3rem;
  margin-top: 1rem;
}
.shadowButton {
  margin-left: 6.5rem;
  margin-top: -3.5rem;
}
.custom-card-title {
  font-weight: bold;
  font-size: 2.13rem;
  text-align: center;
  color: var(--title-color2);
}
.custom-card-sub-title {
  font-weight: bold;
  font-size: 14.6054px;
  line-height: 18px;
  text-align: center;
  color: var(--app-btn-bg);
}
.exploreMainContainer {
  display: flex;
  flex-direction: column;
}

/* featuresCarousal */

.featuresCarousal {
  position: relative;
  display: none;
  gap: 1rem;
  margin: 4rem 0 2rem 0;
}

.featuresCarousalImgContainer h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 8.36868px;
  line-height: 149.5%;
  color: var(--text-blue);
}

.arrow-container {
  display: flex;
  position: absolute;
  bottom: 0.8rem;
  right: -1rem;
}

.arrow-container div {
  width: 26.79px;
  height: 26.79px;
  border: 1.67442px solid var(--text-blue);
  box-sizing: border-box;
  border-radius: 50%;
  display: grid;
  place-content: center;
  margin: 0.2rem;
}

.featuresCarousalTextContainer p {
  font-style: normal;
  font-weight: normal;
  font-size: 6.69494px;
  line-height: 130%;
  width: 92px;
}

.featuresContainer {
  width: 100%;
  margin-top: 42px;
  display: grid;
  grid-template-columns: repeat(3, 35%);
  row-gap: 4rem;
  column-gap: 4rem;
  /* display: grid;
  margin-top: 136px;
  gap: 2rem;
  display: grid;
  padding-right: 10rem;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); */
  /* place-items: end center; */
}

.featuresCard {
  width: 261.35px;
  height: 100%;
  padding: 1rem;
  /* display: grid; */
  /* gap: 2rem; */
  /* grid-template-rows: 50% 50%; */
  /* box-shadow: 7.95219px 2.65073px 7.95219px rgba(156, 172, 242, 0.68); */
  border-radius: 7.50703px;
}

.featuresImgContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.featuresTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}

.featuresTextContainer .imgSection img {
  height: 177px;
}

.featuresCard-img {
  width: 158.96px;
  /* height: 132.05px; */
}

.featuresCard:hover {
  /* background: var(--custom-card-bg); */
  box-shadow: 8px 2px 8.19px rgba(156, 172, 242, 0.44),
    -6px -2px 8.18948px rgba(156, 172, 242, 0.22);
  border-radius: 7.50703px;
  cursor: pointer;
}

/* .featuresCard img {
  width: 202px;
  height: 202px;
} */
.featuresCard h5 {
  color: var(--text-blue);
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 149.5%;
}

.featuresCard p {
  font-weight: 600;
  font-size: 10.1331px;
  line-height: 159%;
  display: flex;
  align-items: center;
  color: var(--title-color2);
  opacity: 0.8;
  padding: 1.2rem 0 0.7rem 0;
}

.featuresCard h4 {
  font-weight: bold;
  font-size: 12.6664px;
  line-height: 149.5%;
  color: var(--text-blue);
  margin-top: 30px;
  display: flex;
  align-items: center;
  /* padding: 1rem 0; */
}

.featuresHeaderContainer {
  font-size: 1.5rem;
  padding: 2rem 5rem;
}
@media screen and (min-width: 1080px) and (max-width: 1300px) {
  .poweredByContainer {
    width: 52%;
  }
}
@media screen and (min-width: 900px) and (max-width: 1079px) {
  .poweredByContainer {
    width: 60%;
  }
}
@media screen and (min-width: 769px) and (max-width: 899px) {
  .poweredByContainer {
    width: 70%;
  }
}
@media only screen and (max-width: 1800px) {
  .poweredByContainer h1 {
    font-size: 17.77px;
    margin: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .featuresContainer {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 2rem;
  }
}
@media only screen and (min-width: 1615px) {
  /* .featuresContainer {
    gap: 4rem;
    display: grid;
    padding-right: 25rem;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  } */
  .featuresContainer {
    width: 100%;
  }
  .our-team-card-container {
    width: 64%;
  }
}

@media only screen and (max-width: 768px) {
  .featuresContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .bannerImgContainer {
    gap: 3rem;
  }
  .poweredByContainer {
    width: 60%;
    padding: 0.5rem;
  }

  .poweredBySubContainer img {
    width: 1rem;
    /* height: 2rem; */
  }

  .poweredByContainer h1 {
    font-size: 8.07696px;
  }

  .poweredBySubContainer {
    width: 2.5rem;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 680px) {
  .featuresContainer {
    width: 100%;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    align-items: center;
  }
}
@media only screen and (max-width: 500px) {
  .featuresContainer {
    display: block;
  }

  .featuresTextContainer .imgSection img {
    display: block;
    margin: 0 auto;
    /* align-items: center;
    justify-content: center; */
  }

  .featuresCard {
    margin: 20px auto;
    align-items: center;
    width: 320px;
  }
  .featuresCarousal {
    display: flex;
  }

  .bannerImg {
    /* display: none; */
    width: 100%;
    margin-top: 20px;
  }
  .bannerImgMobile {
    display: block;
  }
  .bannerImgContainer {
    gap: 1rem;
  }
  .custom-card {
    width: 12.75rem;
    height: 12.2263rem;
    border-radius: 11.737px;
    gap: 0rem;
    background: var(--custom-card-bg);
    box-shadow: 7.95219px 2.65073px 7.95219px var(--card-shadow);
  }
  .custom-card-img {
    /* display: grid;
    place-content: center; */
    width: 16.25rem;
    height: 5.125rem;
  }

  .custom-card-img img {
    width: 109.55px;
    height: 61.48px;
  }
  .custom-card-img img.shadowBtn {
    height: 11px;
    width: 50px;
    margin-left: 31px;
  }
  .custom-card-img img.shadowButton {
    height: 11px;
    width: 20px;
    margin-top: -17px;
    margin-left: 59px;
  }
  .custom-card-title {
    font-weight: bold;
    font-weight: bold;
    font-size: 23.474px;
    text-align: center;
    color: var(--title-color2);
    padding: 0.5rem 0;
  }
  .custom-card-sub-title {
    font-weight: 600;
    font-size: 11.1781px;
    line-height: 14px;
    text-align: center;
    color: var(--app-btn-bg);
  }
  /* .featuresContainer {
    grid-template-columns: 1fr;
  } */
  .poweredByContainer {
    width: 80%;
    margin: 2rem 0 0 0;
    padding: 0.5rem;
  }

  .poweredBySubContainer img {
    width: 0.5rem;
    /* height: 2rem; */
  }

  .poweredByContainer h1 {
    width: 100%;
    font-size: 9px;
  }

  .poweredBySubContainer {
    /* width: 2.5rem; */
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .poweredBySubContainer h1 {
    font-size: 0.5rem;
  }
  .featuresCard h5 {
    font-size: 21.9439px;
    line-height: 149.5%;
  }
  .featuresCard h4 {
    padding: 0;
  }
  .featuresCard {
    height: auto;
    background: var(--custom-card-bg);
    box-shadow: 7.95219px 2.65073px 7.95219px var(--feturecard-shadow);
  }
}

/* Ecosystem */

.our-ecosystem-card-container {
  display: grid;
  /* margin-top: 16px; */
  place-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.our-ecosystem-card-container .slider1 {
  margin-top: 50px;
  display: flex;
  column-gap: 80px;
  margin-bottom: 20px;
  animation: slide 40s linear infinite;
}

.our-ecosystem-card-container .slider2 {
  text-align: center;
  column-gap: 80px;
  margin-bottom: 20px;
  display: flex;
  animation: slide2 40s linear infinite;
  align-items: center;
}

.our-ecosystem-card-container .slider3 {
  text-align: center;
  gap: 80px;
  display: flex;
  animation: slide 40s linear infinite;
}

.our-ecosystem-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 32px;
  background: "#202F44";
  border-radius: 9.479px;
  padding: 15px 29px;
}

.our-ecosystem-card-img {
  width: 100%;
}

.our-ecosystem-card2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 62px;
  background: "#202F44";
  border-radius: 9.479px;
  padding: 15px 29px;
}
.our-ecosystem-card3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 62px;
  background: "#202F44";
  border-radius: 9.479px;
  padding: 15px 29px;
  margin-top: 0;
}

/* explore dapp section */

#explore-dapp-section {
  position: relative;
}

.explore-dapp-container {
  display: flex;
  justify-content: space-between;
  padding: 100px 10rem 0rem 10rem;
  gap: 2rem;
  overflow: hidden;
  /* align-items: center; */
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* align-items: center; */
}


.explore-dapp-content {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  overflow: hidden;
}



.explore-dapp-content-1 img {
  width: 680px;
  height: 614.87px;
}

.coin1 img {
  position: absolute;
  top: 40%;
  left: -1%;
  width: 800px;
  z-index: -2;
}

.coinImg img {
  position: absolute;
  bottom: -40%;
  right: -1%;
  width: 1200px;
  z-index: -1;
}

.explore-dapp-content-2 img {
  width: 677px;
  height: 615px;
}

.explore-dapp-title {
  font-weight: 600;
  margin-bottom: 2.1rem;
  font-size: 53.9975px;
  line-height: 66px;
  display: flex;
  align-items: start;
  flex-direction: column;
  color: var(--title-color2);
}
.explore-dapp-sub-title {
  font-weight: 600;
  font-size: 12.1494px;
  line-height: 149.5%;
  color: var(--title-color2);
  opacity: 0.5;
  max-width: 400px;
  flex-wrap: wrap;
  display: flex;
}
.explore-dapp-button {
  font-weight: bold;
  font-size: 17.622px;
  line-height: 149.5%;
  display: flex;
  align-items: center;
  color: var(--text-blue);
}

@media only screen and (max-width: 1500px) {
  .explore-dapp-content-1 img {
    width: 480px;
    height: 414.87px;
  }
  .explore-dapp-content-2 img {
    width: 480px;
    height: 414.87px;
  }
}
@media only screen and (min-width: 1500px) {
  .explore-dapp-container {
    /* padding: 250px 15rem 0rem 15rem; */
    max-width: 1800px;
    margin: 50px auto;
  }
}
@media only screen and (max-width: 997px) {
  .explore-dapp-content-1 img {
    width: 400px;
    height: auto;
  }
  .explore-dapp-content-2 img {
    width: 400px;
    height: auto;
  }
  .explore-dapp-container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .explore-dapp-title {
    margin-top: 2rem;
  }
  .explore-dapp-content-1 {
    margin-top: 2rem;
  }

  .coin1 {
    display: none;
  }

  .coinImg {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .explore-dapp-content-1 img {
    width: 100%;
    height: auto;
  }
  .explore-dapp-content-2 img {
    width: 100%;
    height: auto;
  }

  .coin1 {
    display: none;
  }

  .coinImg {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .explore-dapp-container {
    flex-direction: column;
    display: flex;
    padding: 104px 4rem 0rem 4rem;
  }
  .explore-dapp-content-1,
  .explore-dapp-content-1 img,
  .explore-dapp-content-2 img {
    align-self: start;
  }

  .coin1 {
    display: none;
  }

  .coinImg {
    display: none;
  }
}

@media only screen and (max-width: 458px) {
  .explore-dapp-container {
    flex-direction: column;
    display: flex;
    padding: 104px 2rem 0rem 2rem;
  }
  .explore-dapp-container:nth-child(3) {
    padding: 104px 2rem 0rem 2rem;
  }
  .explore-dapp-content {
    height: 100%;
    width: 100%;
  }
  .explore-dapp-sub-title {
    height: 100%;
    width: 100%;
  }
  .explore-dapp-content-2 {
    width: 100%;
    height: 100%;
  }
  .explore-dapp-sub-title:nth-child(3) {
    padding-right: 2rem !important;
  }

  .coin1 {
    display: none;
  }

  .coinImg {
    display: none;
  }
}
@media only screen and (max-width: 748px) {
  .custom-container {
    margin-top: 5rem;
    padding: 2rem 2rem 0rem 2rem;
    gap: 2rem;
  }
}

@media only screen and (min-width: 1800px) {
  .custom-container {
    column-gap: 8rem;
    row-gap: 8rem;
  }
  .explore-content-margin {
    margin-left: 27%;
  }
}

/* Our Ecosystem section */

#our-ecosystem-section {
  display: flex;
  flex-flow: column;
  align-items: center;
}
#our-ecosystem-section div.container {
  transition: all 0.3s ease;
}
#our-ecosystem-section div.container h1 {
  margin: 15px 0 0 0;
}
#our-ecosystem-section div.container h3 {
  margin: 0 0 25px 0;
}
@media (max-width: 992px) {
  #our-ecosystem-section {
    /* padding: 0 20px 0 20px; */
  }
}
#our-ecosystem-section .slide-option {
  margin: 0 0 50px 0;
}
#our-ecosystem-section .slide-option .no-marg {
  margin: 0 0 0 0;
}
div.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 150px;
}
div.highway-slider div.highway-barrier {
  overflow: hidden;
  position: relative;
}
div.highway-slider ul.highway-lane {
  display: flex;
  height: 100%;
}
div.highway-slider ul.highway-lane li.highway-car {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes translatestf {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-500%);
  }
}
@keyframes translatestf {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-500%);
  }
}
#stffull div.highway-barrier ul.highway-lane {
  width: 500%;
}
#stffull div.highway-barrier ul.highway-lane li.highway-car {
  -webkit-animation: translatestf 30s linear infinite;
  animation: translatestf 30s linear infinite;
  animation-direction: alternate;
}
#stffull div.highway-barrier ul.highway-lane li.highway-car h4 {
  font-size: 28px;
}
@-webkit-keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 12));
  }
}
@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-180px * 12));
  }
}

#infinite div.highway-barrier ul.highway-lane {
  width: calc(180px * 24);
}
#reverve_infinite div.highway-barrier ul.highway-lane {
  width: calc(180px * 24);
}
#infinite div.highway-barrier ul.highway-lane li.highway-car {
  width: 180px;
  -webkit-animation: translateinfinite 25s linear infinite;
  animation: translateinfinite 25s linear infinite;
}
#reverve_infinite div.highway-barrier ul.highway-lane li.highway-car {
  width: 180px;
  -webkit-animation: translateinfinite 25s linear infinite;
  animation: translateinfinite 25s linear infinite;
  animation-direction: reverse;
}

#infinite div.highway-barrier ul.highway-lane li.highway-car span.fab {
  font-size: 65px;
}

#footer-section {
  padding: 250px 0 0 0;
}
@media only screen and (max-width: 768px) {
  div.highway-slider {
    height: max-content;
  }
  #infinite div.highway-barrier ul.highway-lane {
    width: calc(100px * 24);
  }
  #reverve_infinite div.highway-barrier ul.highway-lane {
    width: calc(100px * 24);
  }
}

.common-container {
  display: flex;
  flex-direction: column;
  padding: 250px 10rem 0rem 10rem;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.container-title {
  font-weight: 600;
  font-size: 54px;
  line-height: 66px;
  display: flex;
  align-items: start;
  flex-direction: column;
  color: var(--title-color2);
  width: auto;
}
.container-title-sub {
  font-weight: bold;
}
.container-sub-title {
  font-weight: 600;
  font-size: 16.3968px;
  line-height: 20px;
  color: var(--title-color2);
  opacity: 0.6;
  margin-top: 1rem;
}
@media only screen and (max-width: 768px) {
  .container-title {
    font-weight: 600;
    font-size: 35.2688px;
    line-height: 43px;
  }
  .container-sub-title {
    font-size: 8px;
    line-height: 9px;
  }
  .common-container {
    padding: 104px 4rem 0rem 4rem;
  }
  .explore-dapp-title {
    font-size: 35.2688px;
    line-height: 43px;
    margin-bottom: 1.1rem;
  }
  #footer-section {
    padding: 104px 0 0 0;
  }
}
@media only screen and (max-width: 476px) {
  .common-container {
    padding: 104px 2rem 0rem 2rem;
  }
  #footer-section {
    padding: 0;
  }
}
.features-card-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  /* background: var(--white2); */
  border-radius: 24.5652px;
  /* width: 349px; */
  /* height: 458.6px; */
}

/* Meet our team section */

.our-team-card-container {
  justify-content: flex-start;
  gap: 2rem;
  display: flex;
  margin-top: 136px;
  max-width: 80%;
  flex-wrap: wrap;
}
.our-team-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  gap: 1rem;
}
.our-team-card-img {
  width: 54%;
  height: 100%;
  border-radius: 50%;
}
.our-team-title {
  font-weight: 600;
  font-size: 16.4721px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: var(--our-team-title);
  opacity: 0.8;
}
.our-team-sub-title {
  font-weight: bold;
  font-size: 21.1785px;
  line-height: 149.5%;
  display: flex;
  align-items: center;
  color: var(--app-btn-bg);
}

.our-team-card-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  gap: 3rem;
  /* background: var(--white3); */
  background: var(--custom-card-bg);
  border-radius: 24.5652px;
  /* width: 349px; */
  height: 458.6px;
}
.our-team-card-mobile-img {
  width: 183.87px;
  height: 183.87px;
  border-radius: 50%;
}
.our-team-mobile-social {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 30%;
}

.our-team-title-mobile {
  font-weight: 600;
  font-size: 15.0689px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: var(--our-team-title);
  justify-content: center;
  opacity: 0.8;
}
.our-team-sub-title-mobile {
  font-weight: bold;
  font-size: 19.3743px;
  line-height: 149.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--app-btn-bg);
}
.carousel-indicators button {
  background: var(--app-btn-bg) !important;
  opacity: 0.34 !important;
  border-radius: 166px !important;
  width: 11px !important;
  height: 5px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
}

.carousel-indicators .active {
  opacity: 1 !important;
  background: var(--app-btn-bg) !important;
}

@media only screen and (max-width: 500px) {
  .our-team-card-container {
    display: none !important;
  }
}
@media only screen and (min-width: 501px) {
  .our-team-card-container-mobile {
    display: none !important;
  }
}
@media only screen and (min-width: 1575px) {
  .common-container {
    padding: 250px 10rem 0rem 10rem;
    max-width: 1800px;
    margin: auto;
  }
}

/* our ecosystem section */
.our-ecosystem-card-container {
  gap: 3rem;
  display: grid;
  margin-top: 16px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  place-items: end center;
  width: 100%;
}
.our-ecosystem-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 196.87px;
  height: 74.37px;
  background: var(--eco-card-bg);
  border-radius: 8.87295px;
  padding: 0.9rem 1.4rem;
}
.our-ecosystem-card-img {
  width: 100%;
  /* height: 100%; */
}

@media only screen and (max-width: 458px) {
  .explore-dapp-title {
    font-weight: 600;
    font-size: 35.2688px;
    line-height: 43px;
  }
}
@media only screen and (max-width: 768px) {
  .our-team-card-container {
    margin-top: 68px;
    max-width: none;
  }
  .our-ecosystem-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(101.03px, 1fr));
    place-items: end center;
    margin-top: 10px;
    gap: 1rem;
  }
  .our-ecosystem-card {
    width: 101.03px;
    height: 38.17px;
  }
}

/* Get involved with us section */

.get-involved-container {
  display: flex;
  justify-content: space-between;
  padding: 250px 10rem 0rem 10rem;
  gap: 3rem;
}
.get-involved-content {
  display: flex;
  flex-basis: 40%;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
  overflow: hidden;
}
.get-involved-content-2 {
  display: flex;
  flex-basis: 39%;
  flex-direction: column;
  gap: 3rem;
  margin: -5px 0 0 0;
  overflow: hidden;
}
.get-involved-title {
  font-weight: 600;
  font-size: 54px;
  line-height: 66px;
  display: flex;
  align-items: start;
  flex-direction: column;
  color: var(--title-color2);
}
.get-involved-sub-title {
  font-weight: 600;
  font-size: 16.39px;
  line-height: 149.5%;
  opacity: 0.5;
  width: auto;
  flex-wrap: wrap;
  display: flex;
  max-width: 552.35px;
}
.get-involved-content-title {
  font-weight: bold;
  font-size: 41.8425px;
  line-height: 51px;
  display: flex;
  align-items: center;
  color: var(--title-color2);
  margin-bottom: 1rem;
}
.get-involved-content-sub-title {
  font-weight: 600;
  font-size: 16.2705px;
  line-height: 20px;
  max-width: 607.22px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: var(--title-color2);
  opacity: 0.6;
}
.get-involved-button {
  width: 250px;
  height: 65px;
  background: var(--app-btn-bg);
  border-radius: 35.3793px;
  border: none;
  font-weight: bold;
  font-size: 23.28px;
  line-height: 28px;
  color: var(--app-btn-color);
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .get-involved-container {
    flex-direction: column;
    display: flex;
    padding: 104px 4rem 0rem 4rem;
  }
  .get-involved-title {
    font-size: 35.2688px;
    line-height: 43px;
  }
  .get-involved-content-title {
    font-weight: 600;
    font-size: 30.2688px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 476px) {
  .get-involved-container {
    padding: 104px 2rem 0rem 2rem;
  }
}
@media only screen and (min-width: 1500px) {
  .get-involved-container {
    padding: 250px 10rem 0rem 10rem;
    /* padding: 250px 10rem; */
    max-width: 1800px;
    margin: auto;
  }
}
/* Blog & News section */

.blog-card-container {
  gap: 3rem;
  display: flex;
  /* justify-content: center; */
  margin-top: 136px;
}
.blog-card-container a {
  /* width: 400px; */
}

.blog-card {
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 20px; */
  /* flex-basis: 30%; */
}
.blog-card-img {
  width: 100%;
  height: 210px;
  border-radius: 20px 20px 0px 0px;
}
.blog-card-content {
  font-weight: bold;
  font-size: 22.9577px;
  line-height: 134%;
  letter-spacing: -0.005em;
  color: var(--title-color2);
}

@media only screen and (min-width: 2000px) {
  .blog-card-img {
    width: 100%;
    height: 247px;
    border-radius: 20px 20px 0px 0px;
  }
}

@media only screen and (max-width: 768px) {
  .blog-card-container {
    flex-direction: column;
    display: flex;
    margin-top: 68px;
  }
  .blog-card-content {
    width: 100%;
    padding: 0rem 1.5rem;
  }
  .blog-card {
    background: var(--app-btn-color);
    box-shadow: 4.73503px 2.84102px 7.57604px 3.78802px rgba(0, 0, 0, 0.1);
    border-radius: 12.6163px;
    padding: 0rem 0rem 2rem 0rem;
  }
}

/* They Write About Us section */

.write-aboutus-card-container {
  column-gap: 3rem;
  row-gap: 6rem;
  width: 100%;
  display: grid;
  margin-top: 136px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  place-items: end start;
}
.write-aboutus-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  /* width: 335px; */
}

.write-aboutus-card-content {
  font-weight: bold;
  font-size: 20px;
  line-height: 134%;
  color: var(--title-color2);
  opacity: 0.8;
  text-align: left;
}

.write-aboutus-card-sub-content {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.write-aboutus-card-date {
  font-weight: 500;
  font-size: 14.2669px;
  line-height: 17px;
  color: var(--title-color2);
  opacity: 0.5;
}
.write-aboutus-card-crypto {
  font-weight: bold;
  font-size: 17.49px;
  line-height: 21px;
  color: var(--text-blue);
}

@media only screen and (min-width: 1800px) {
  .write-aboutus-card-container {
    column-gap: 7rem;
    row-gap: 6rem;
    grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  }
}
@media screen and (min-width: 1500px) and (max-width: 1799px) {
  .write-aboutus-card-container {
    row-gap: 6rem;
    grid-template-columns: repeat(auto-fit, minmax(335px, 1fr));
  }
}
@media only screen and (max-width: 768px) {
  .write-aboutus-card-container {
    margin-top: 68px;
  }
}

@media only screen and (max-width: 458px) {
  .write-aboutus-card-container {
    grid-template-columns: repeat(auto-fit, minmax(218px, 1fr));
  }
  .write-aboutus-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
}

/*join our community section */

.our-community-card-container {
  justify-content: center;
  align-items: center;
  gap: 4rem;
  display: flex;
  margin-top: 136px;
  flex-wrap: wrap;
  position: relative;
}
.our-community-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  width: 182px;
  height: 140px;
  background: var(--custom-card-bg);
  box-shadow: 12px 4px 12px var(--feturecard-shadow);
  border-radius: 11px;
}
.our-community-card-container-web {
  justify-content: space-between;
  display: flex;
  position: absolute;
  width: 100%;
  /* bottom: 0; */
}
.our-community-card-container-mobile {
  justify-content: space-between;
  display: flex;
  /* margin: 0rem -2rem; */
}
.our-community-card-container-web img {
  z-index: -1;
  height: 100%;
}
.our-community-card-container-mobile img {
  z-index: -1;
  height: 100%;
  width: 80%;
}
.our-community-card-img {
  width: 41px;
  height: 34px;
}
.our-community-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--title-color2);
}

/* .community-wrapper {
  background: var(--light-gray);
  opacity: 0.08;
  width: 100%;
  height: 30vh;
  position: absolute;
  z-index: -1;
  top: 2%;
} */

@media only screen and (max-width: 768px) {
  .our-community-card-container {
    margin-top: 68px;
  }
  .our-community-card-container-web {
    display: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .our-community-card-container-mobile {
    display: none !important;
  }
}
@media only screen and (max-width: 500px) {
  .our-community-card-container {
    gap: 2rem;
  }
  .our-community-card {
    width: 104.28px;
    height: 80.22px;
  }
  .our-community-card-img {
    width: 23.49px;
    height: 19.48px;
  }
  .our-community-title {
    font-size: 9.1676px;
    line-height: 10px;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 2rem;
  /* padding-left: 10rem; */
}
.footer-container ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 0;
}
.footer-title {
  font-weight: bold !important;
  font-size: 23.9109px !important;
  line-height: 29px !important;
  color: var(--title-color) !important;
  margin-bottom: 2rem !important;
}

.footer-container li {
  font-weight: 600;
  font-size: 17.3897px;
  line-height: 21px;
  color: var(--footer-li-text);
}

.footer-btn {
  width: 152.16px;
  height: 43.47px;
  background: var(--app-btn-bg);
  border-radius: 32.6058px;
  font-weight: bold;
  font-size: 17.3897px;
  line-height: 21px;
  text-align: center;
  color: var(--app-btn-color);
  border: none;
}
.footer-input-content {
  border: none;
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
.footer-input-content img {
  width: 19.45px;
  height: 16.45px;
}
.footer-input {
  border: none;
  padding: 0.5rem 0rem;
  width: 100%;
  outline: none;
  font-size: 15.216px;
}
.social-media-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem 20% 1rem 15rem;
  gap: 2rem;
  position: relative;
}

.logo-content {
  display: flex;
  /* justify-content: center; */
  margin-top: 2rem;
  gap: 3.2rem;
  align-items: center;
}
.logo-content-mobile {
  display: none;
}
.logo-content-web {
  position: absolute;
  top: -3rem;
  left: 1%;
}
.logo-content-web img {
  width: 202.12px;
  height: 40.14px;
}

.footer-first-child {
  position: relative;
}

.logo-content-img {
  width: 27.48px;
  height: auto;
}

/* copyright section */
.copyright-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1.08686px solid var(--gray);
  flex-wrap: wrap;
  margin: 0 6%;
}
.copyright-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 2rem;
  color: var(--gray3);
}
.copyright-text-mobile {
  display: none;
}

@media only screen and (max-width: 1269px) {
  /* .logo-content-mobile {
    display: block;
  }
  .logo-content {
    display: none;
  } */

  .logo-content-web {
    display: none;
  }
  .social-media-container {
    margin-top: 3rem;
  }

  .logo-content {
    position: absolute;
    bottom: -5rem;
  }
  .footer-last-child {
    position: relative;
  }
  .social-media-container {
    padding: 3rem 20% 3rem 8rem;
  }
}
@media only screen and (min-width: 1712px) {
  .footer-last-child {
    width: 30%;
  }
}
@media only screen and (max-width: 1575px) {
  .social-media-container {
    padding: 1rem 20% 1rem 10rem;
  }
}
/* @media only screen and (max-width: 1711px) {
  .footer-last-child {
    width: 34%;
  }
} */
@media only screen and (max-width: 1200px) {
  .logo-content-web {
    display: none !important;
  }
  .footer-input-content {
    width: 80%;
  }
}

@media only screen and (max-width: 820px) {
  .logo-content-web {
    padding-left: 0;
  }
  .copyright-container {
    margin: 0;
  }
  .footer-input-content {
    width: 100%;
  }
  .logo-content {
    display: none;
  }
  .logo-content-mobile {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
@media only screen and (max-width: 494px) {
  .footer-input-content {
    width: 100%;
  }
  .logo-content-web {
    display: none;
  }
  .copyright-text {
    font-weight: 500;
    font-size: 13.6944px;
    line-height: 17px;
  }
  .our-community-card-container-mobile img {
    width: 55%;
  }

  .footer-container {
    gap: 1rem;
    position: relative;
    padding-left: 0;
  }

  .footer-container ul {
    padding-left: 0;
  }

  .footer-title {
    margin: 0 !important;
    margin-top: 1rem !important;
  }
  .copyright-text {
    display: none;
  }
  .copyright-text-mobile {
    display: block;
    opacity: 0.8;
    border-top: 1.08686px solid var(--gray);
    padding: 1rem 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13.6944px;
    line-height: 17px;
    text-align: center;
  }
  .social-media-container {
    display: grid;
    justify-content: center;
    padding: 1rem 0;
    border-top: 1.08686px solid var(--gray);
    opacity: 0.8;
    border-bottom: 1.08686px solid var(--gray2);
    margin-bottom: 2rem;
    margin-top: 2.5rem;
  }
  .subscribe-text {
    display: none;
  }
  .logo-content {
    display: none;
  }
  .logo-content-mobile {
    display: flex;
    justify-content: space-evenly;
    /* margin-top: 1rem; */
    gap: 1rem;
    align-items: center;
  }
}
@media only screen and (max-width: 626px) {
  .logo-content {
    right: -2%;
  }
}

@media screen and (min-width: 376px) and (max-width: 494px) {
  .logo-content {
    gap: 2rem;
  }
  .logo-content-web {
    gap: 2rem;
  }
}
@media screen and (min-width: 301px) and (max-width: 375px) {
  .logo-content {
    gap: 1.2rem;
  }
  .logo-content-web {
    gap: 1.2rem;
  }
}

@media screen and (min-width: 200px) and (max-width: 300px) {
  .logo-content {
    gap: 0.8rem;
  }
  .logo-content-web {
    gap: 0.8rem;
  }
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 10));
  }
}

@keyframes slide2 {
  0% {
    transform: translateX(calc(-250px * 9));
  }
  100% {
    transform: translateX(0);
  }
}
